import React from 'react';
import './styles/App.scss';

const App = () => {
    return (
        <div id="App">
            <h1>ARLEN PAN</h1>
            <h2>software engineer at RedCircle</h2>
            <h2>
                find me at <a href="mailto:arlenpan@gmail.com">arlenpan@gmail.com</a>
            </h2>
            <ul>
                <li>
                    <a href="https://www.linkedin.com/in/arlenpan/" target="_blank" rel="noopener noreferrer">
                        linkedin
                    </a>
                </li>
                <li>
                    <a href="https://www.github.com/arlenpan/" target="_blank" rel="noopener noreferrer">
                        github
                    </a>
                </li>
                <li>
                    <a href="https://www.500px.com/arlenpan/" target="_blank" rel="noopener noreferrer">
                        500px
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default App;
